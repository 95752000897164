<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    FUNNELS
                </div>
                <div class="page_head-total-btn">
                    {{total_point}} Total
                </div>
            </div>
            <div class="page_head-item">
                <b-button class="page_head-new-btn" @click="newFunnel()" id="funnel_btn">+Create New Funnel</b-button>
            </div>
        </div>
        <div class="page_subtitle">Funnels help you customize mass communication campaigns based on where leads are in
            the buyer or seller process
        </div>
        <div class="page_content">
            <b-table hover :items="allFunnels" :fields="fields" responsive="sm">
                <template v-slot:cell(name)="data">
                    <div class="item_name item_center">
                        {{data.item.funnel_name}}
                    </div>
                </template>
                <template v-slot:cell(subscribers)="data">
                    <div class="item_center">
                        {{getData(data.item.subscribers)}}
                    </div>
                </template>
                <template v-slot:cell(frequency)="data">
                    <div class="item_center">
                        {{getData(data.item.frequency)}}
                    </div>
                </template>
                <template v-slot:cell(emails)="data">
                    <div class="item_center">
                        {{getData(data.item.emails_sent)}}
                    </div>
                </template>
                <template v-slot:cell(texts_sent)="data">
                    <div class="item_center">
                        {{getData(data.item.texts_sent)}}
                    </div>
                </template>
                <template v-slot:cell(response_rate)="data">
                    <div class="item_center">
                        {{getData(data.item.response_rate)}}
                    </div>
                </template>

                <template v-slot:cell(funnel)="data">
                    <div class="item_switch">
                        <b-form-checkbox switch size="lg" :checked="1" :unchecked-value="0" :value="data.item.funnel_on_off" @change="funnelChange" />
                    </div>
                </template>

                <template v-slot:cell(view)="data">
                    <div class="item_view" id="funnel_view" @click="funnelViewModal(data.item)">
                    </div>
                </template>
                <template v-slot:cell(edit)="data">
                    <div class="item_edit" id="funnel_edit-btn" @click="funnelEditModal(data.item)">
                    </div>
                </template>
                <template v-slot:cell(delete)="data">
                    <div class="item_delete" id="funnel_delete" @click="funnelDeleteModal(data.item)">
                    </div>
                </template>
            </b-table>
        </div>
        <!--Add Funnel modal start-->
        <b-modal ref="funnel-modal" centered hide-header hide-footer hide-header-close>
            <div class="funnel_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="funnelModal()"></b-icon>
                <div class="d-flex">
                    <div class="funnel_title">
                        CREATE NEW FUNNEL
                    </div>
                </div>

                <div class="input_content">
                    <div class="single_input">
                        <div class="input_item">
                            <label>Name your funnel <span>(only you will see this)</span></label>
                            <b-form-input
                                    placeholder="name"
                                    v-model="funnel_data.funnel_name"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="funnelErrors['funnel_name']" class="text_danger mt-1">{{funnelErrors['funnel_name'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Funnel Text</label>
                            <b-form-textarea
                                    v-model="funnel_data.funnel_text"
                                    placeholder="text"
                                    rows="4"
                                    max-rows="6"
                            ></b-form-textarea>
                            <span v-if="funnelErrors['funnel_text']" class="text_danger mt-1">{{funnelErrors['funnel_text'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label class="mb-0">Frequency of Message (Days)</label>
                            <div class="input_item mb-2">
                                <b-form-radio-group v-model="funnel_data.frequency_value" :options="frequencyOptions"
                                                    name="radio-validation">
                                </b-form-radio-group>
                            </div>
                            <div class="select_arrow">
                                <b-form-select v-model="funnel_data.frequency"
                                               :disabled="funnel_data.frequency_value !== 3">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                                    </template>
                                    <option
                                            v-for="(option, id) in 99"
                                            :key="id"
                                            :value="option"
                                            :title="option || null"
                                    >
                                        {{option}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="funnelErrors['frequency']" class="text_danger mt-1">{{funnelErrors['frequency'][0]}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Leads</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="chosen_leads"
                                        :options="allLeads"
                                        label="name"
                                        track-by="id"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="dual_inputs-switch">-->
<!--                        <div class="input_item-switch">-->
<!--                            <label>Funnel</label>-->
<!--                            <b-form-checkbox switch size="lg" v-model="funnel_on_off"/>-->
<!--                        </div>-->
<!--                        <div class="input_item">-->
<!--                            <label>Leads</label>-->
<!--                            <div class="select_arrow">-->
<!--                                <Multiselect-->
<!--                                        v-model="chosen_leads"-->
<!--                                        :options="allLeads"-->
<!--                                        label="name"-->
<!--                                        track-by="id"-->
<!--                                        :close-on-select="false"-->
<!--                                        :hide-selected="true"-->
<!--                                        multiple-->
<!--                                ></Multiselect>-->
<!--                                <div class="arrow_down"></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="addNewFunnel()">Create funnel</b-button>
                </div>
            </div>
        </b-modal>
        <!--Add Funnel modal end-->

        <!--Funnel view modal start-->
        <b-modal ref="funnel_view" centered hide-header hide-footer hide-header-close>
            <div class="funnel-view_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="funnelModalView()"></b-icon>
                <div class="d-flex">
                    <div class="funnel-view_title">
                        {{view_data.funnel_name}}
                    </div>
                </div>
                <div class="funnel-view_description">{{view_data.funnel_text}}</div>
                <div class="funnel-view_footer-container">
                </div>
            </div>
        </b-modal>
        <!--Funnel view modal end-->

        <!--Edit Funnel modal start-->
        <b-modal ref="funnel_edit-modal" centered hide-header hide-footer hide-header-close>
            <div class="funnel_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="editFunnelModal()"></b-icon>
                <div class="d-flex">
                    <div class="funnel_title">
                        EDIT FUNNEL
                    </div>
                </div>

                <div class="input_content">
                    <div class="single_input">
                        <div class="input_item">
                            <label>Name your funnel <span>(only you will see this)</span></label>
                            <b-form-input
                                    placeholder="name"
                                    v-model="funnel_edit_data.funnel_name"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="funnelEditErrors['funnel_name']" class="text_danger mt-1">{{funnelEditErrors['funnel_name'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Funnel Text</label>
                            <b-form-textarea
                                    v-model="funnel_edit_data.funnel_text"
                                    placeholder="text"
                                    rows="4"
                                    max-rows="6"
                            ></b-form-textarea>
                            <span v-if="funnelEditErrors['funnel_text']" class="text_danger mt-1">{{funnelEditErrors['funnel_text'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Frequency of Message (Days)</label>
                            <div class="select_arrow">
                                <b-form-select v-model="funnel_edit_data.frequency">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                                    </template>
                                    <option
                                            v-for="(option, id) in 99"
                                            :key="id"
                                            :value="option"
                                            :title="option || null"
                                    >
                                        {{option}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="funnelEditErrors['frequency']" class="text_danger mt-1">{{funnelEditErrors['frequency'][0]}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Leads</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="funnel_edit_data.leads"
                                        :options="allLeads"
                                        label="name"
                                        track-by="id"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="dual_inputs-switch">-->
<!--                        <div class="input_item-switch">-->
<!--                            <label>Funnel</label>-->
<!--                            <b-form-checkbox switch size="lg" v-model="funnel_on_off"/>-->
<!--                        </div>-->
<!--                        <div class="input_item">-->
<!--                            <label>Leads</label>-->
<!--                            <div class="select_arrow">-->
<!--                                <Multiselect-->
<!--                                        v-model="chosen_leads"-->
<!--                                        :options="allLeads"-->
<!--                                        label="name"-->
<!--                                        track-by="id"-->
<!--                                        :close-on-select="false"-->
<!--                                        :hide-selected="true"-->
<!--                                        multiple-->
<!--                                ></Multiselect>-->
<!--                                <div class="arrow_down"></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="funnelEdit()">Edit funnel</b-button>
                </div>
            </div>
        </b-modal>
        <!--Edit Funnel modal end-->

        <!--Funnel delete modal start-->
        <b-modal ref="funnel_delete" centered hide-header hide-footer hide-header-close>
            <div class="funnel-delete_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="funnelDelModal()"></b-icon>
                <div class="d-flex">
                    <div class="funnel-delete_title">
                        You are about to delete this funnel
                    </div>
                </div>
                <div class="funnel-delete_message">
                    Do you want to proceed?

                </div>

                <div class="delete_btn-container">
                    <b-button class="delete_btn" @click="removeFunnel()">Delete</b-button>
                </div>
            </div>
        </b-modal>
        <!--Funnel delete modal end-->


    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "Funnels",
        data() {
            return {
                borderPosition: true,
                total_point: 10,
                fields: [
                    {
                        key: 'name',
                        label: 'NAME',
                    },
                    {
                        key: 'subscribers',
                        label: 'SUBSCRIBERS',
                    },
                    {
                        key: 'frequency',
                        label: 'FREQUENCY',
                    },
                    {
                        key: 'emails',
                        label: 'EMAIL RESPONSES',
                    },
                    {
                        key: 'texts_sent',
                        label: 'TEXT RESPONSES',
                    },
                    {
                        key: 'response_rate',
                        label: 'RESPONSE RATE',
                    },
                    {
                        key: 'funnel',
                        label: 'FUNNEL',
                    },
                    {
                        key: 'view',
                        label: 'VIEW',
                    },
                    {
                        key: 'edit',
                        label: 'EDIT'
                    },
                    {
                        key: 'delete',
                        label: 'DELETE',
                    },

                ],
                allFunnels: [],
                view_data: '',
                funnel_data: {
                    funnel_name: null,
                    funnel_text: null,
                    frequency_value: null,
                    frequency: null,
                    leads: []
                },
                funnel_edit_data: {
                    funnel_name: null,
                    funnel_text: null,
                    frequency: null,
                    frequency_value: 3,
                    funnel_edit_id: null,
                    leads: [],
                    leads_id: []
                },
                remove_funnel_id: null,
                allLeads: [],
                chosen_leads: [],
                funnelErrors: [],
                funnelEditErrors: [],
                frequencyOptions: [
                    {text: 'Once a week', value: 1},
                    {text: 'Once a month', value: 2},
                    {text: 'Other', value: 3},
                ],
            }
        },
        created() {
            alert.$on("clickOn", border => {
                this.borderPosition = border;
            })
            this.getAllFunnels()
                .then(res => {
                    if (res.status === 200) {
                        this.allFunnels = res.data.success
                    }
                })
        },
        methods: {
            ...mapActions(['createFunnel', 'getAllFunnels', 'getAllLeads', 'deleteFunnel', 'editFunnel']),
            newFunnel() {
                this.funnel_data.funnel_name = null
                this.funnel_data.funnel_text = null
                this.funnel_data.frequency_value = null
                this.funnel_data.frequency = null
                this.chosen_leads = []
                this.funnelErrors = []
                this.getAllLeads()
                    .then(res => {
                        if (res.status === 200) {
                            this.allLeads = res.data.success
                        }
                    })
                this.funnelModal()
            },
            funnelModal() {
                this.$refs['funnel-modal'].toggle('#funnel_btn')
            },
            addNewFunnel() {
                this.chosen_leads.forEach(item => {
                    this.funnel_data.leads.push(item.id)
                })
                // if (this.funnel_data.frequency_value !== '') {
                //     this.funnel_data.frequency = this.funnel_data.frequency_value
                // }
                this.createFunnel(this.funnel_data)
                    .then(res => {
                        if (res.status === 200) {
                            this.funnelModal()
                            this.getAllFunnels()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.allFunnels = res.data.success
                                    }
                                })
                        } else {
                            this.funnelErrors = res.response.data.errors
                        }
                    })
            },
            funnelChange(args) {
                console.log(args)
            },
            funnelViewModal(item) {
                this.view_data = item
                this.funnelModalView()
            },
            funnelModalView() {
                this.$refs['funnel_view'].toggle('#funnel_view')
            },
            funnelEditModal(item) {
                console.log(item, 'edit item')
                this.getAllLeads()
                    .then(res => {
                        if (res.status === 200) {
                            this.allLeads = res.data.success
                        }
                    })
                this.funnel_edit_data.funnel_name = item.funnel_name
                this.funnel_edit_data.funnel_text = item.funnel_text
                this.funnel_edit_data.frequency = item.frequency
                // this.funnel_edit_data.frequency_value = item.frequency_type
                this.funnel_edit_data.funnel_edit_id = item.id
                this.funnel_edit_data.leads = item.leads
                console.log(this.funnel_edit_data)
                this.editFunnelModal()
            },
            editFunnelModal() {
                this.$refs['funnel_edit-modal'].toggle('#funnel_edit-btn')
            },
            funnelEdit() {
                this.funnel_edit_data.leads.forEach(item => {
                    this.funnel_edit_data.leads_id.push(item.id)
                })
                this.editFunnel(this.funnel_edit_data)
                    .then(res => {
                        if (res.status === 200) {
                            this.editFunnelModal()
                            this.getAllFunnels()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.allFunnels = res.data.success
                                    }
                                })
                        } else {
                            this.funnelEditErrors = res.response.data.errors
                        }
                    })
            },
            funnelDeleteModal(item) {
                this.remove_funnel_id = item.id
                this.funnelDelModal()
            },
            funnelDelModal() {
                this.$refs['funnel_delete'].toggle('#funnel_delete')
            },
            removeFunnel() {
                this.deleteFunnel(this.remove_funnel_id)
                    .then(res => {
                        if (res.status === 200) {
                            this.funnelDelModal()
                            this.getAllFunnels()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.allFunnels = res.data.success
                                    }
                                })
                        }
                    })
            },
            getData(item) {
                if (item === null) {
                    return '_'
                } else {
                    return item
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1700px;
            width: 100%;
            margin: 0 auto;
            transition: .4s;

            &.straight_length {
                max-width: 1600px;
                transition: .4s;
            }
        }

        &_head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 30px 5px;

            &-item {
                display: flex;
                align-items: center;
            }

            &-title {
                color: $main_blue;
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 0;
                text-transform: uppercase;
            }

            &-new-btn {
                width: 280px;
                height: 44px;
                border-radius: 5px;
                background: $light_blue;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
                border: none;

                &:focus {
                    box-shadow: none;
                }

                &:active {
                    background: $main_blue !important;
                }
            }

            &-total-btn {
                width: 126px;
                height: 44px;
                border: 1px solid #00457B;
                border-radius: 22px;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                color: $main_blue;
                margin: 0 15px;
            }
        }

        &_subtitle {
            width: 100%;
            display: flex;
            padding: 0 30px 20px;
            justify-content: flex-start;
            color: $light_blue;
        }

        &_content {
            width: 100%;
            padding: 10px 30px 0;

            ::v-deep table {
                border: 1px solid #dee2e6;

                td {
                    vertical-align: middle;
                }
            }

            ::v-deep .table thead th {
                color: $main_blue;
                text-transform: uppercase;
                text-align: center;
            }

            & .item {
                &_name {
                    color: $light_blue;
                }

                &_center {
                    display: flex;
                    justify-content: center;
                }
                &_switch {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &_view {
                    width: 30px;
                    height: 20px;
                    background: url("~@/assets/images/view_icon.svg") center no-repeat;
                    background-size: contain;
                    margin: 0 auto;
                    cursor: pointer;
                }

                &_edit {
                    width: 30px;
                    height: 20px;
                    background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                    background-size: contain;
                    margin: 0 auto;
                    cursor: pointer;
                }

                &_delete {
                    width: 24px;
                    height: 30px;
                    background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                    background-size: contain;
                    margin: 0 auto;
                    cursor: pointer;
                }
            }
        }
    }

    .funnel-view,
    .funnel-delete {
        &_modal_content {
            position: relative;
            padding: 0 20px 10px;
            width: 560px;

            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: #B4BABC;
            }

            & .input_content {
                display: flex;
                flex-direction: column;
            }

            & .delete_btn {
                background: $light_blue;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                width: 50%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 400;
                font-size: 18px;
                border: none;

                &-container {
                    display: flex;
                    justify-content: center;
                    padding: 20px 0 5px;
                }
            }
        }

        &_message {
            padding: 20px 30px 40px;
            color: $grey;
            font-size: 22px;
        }

        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $main_blue;
            padding: 20px 0 10px;
            display: flex;
            justify-content: center;
            text-transform: uppercase;
        }

        &_description {
            padding: 15px 0;
            display: flex;
            width: 100%;
            min-height: 150px;
        }

        &_footer-container {
            display: flex;
            justify-content: center;
            padding: 20px 0 10px;
            width: 100%;
            height: 60px;
            background: url("~@/assets/images/rows.svg") center no-repeat;
            background-size: contain;
        }
    }

    .funnel {
        &_modal_content {
            position: relative;
            padding: 0 20px;
            width: 560px;

            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: #B4BABC;
            }

            & .input_content {
                display: flex;
                flex-direction: column;

                & .single_input {
                    & .input_item {
                        padding: 5px 0;
                        width: 100%;

                        & .select_arrow {
                            position: relative;

                            & .arrow_down {
                                position: absolute;
                                top: 14px;
                                right: 15px;
                                width: 14px;
                                height: 10px;
                                pointer-events: none;
                                background: url("~@/assets/images/arrow_down.svg") right no-repeat;
                            }
                        }

                        & label {
                            color: $black;
                            font-weight: 500;
                            font-size: 16px;

                            & span {
                                color: $grey;
                            }
                        }

                        & input {
                            border: 1px solid #798387;
                            border-radius: 5px;

                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }
                        }

                        & select {
                            background: transparent;
                            border: 1px solid #798387;
                            border-radius: 5px;
                            padding-right: 5px;
                        }

                        & textarea {
                            overflow: auto;
                            border: 1px solid #798387;
                            border-radius: 5px;

                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }
                    }
                }

            }

            & .done_btn-container {
                display: flex;
                justify-content: center;
                padding: 20px 0 10px;

                & .done_btn {
                    background: $light_blue;
                    box-shadow: 0 3px 6px #00000029;
                    border-radius: 5px;
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $white;
                    font-weight: 400;
                    font-size: 18px;
                    border: none;
                }
            }
        }

        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $main_blue;
            padding: 20px 0 10px;
            display: flex;
            justify-content: center;
            text-transform: uppercase;
        }
    }

    ::v-deep .modal-content {
        border: none !important;
        width: 600px;

        ::v-deep .modal-body {
            width: 580px !important;
        }
    }

    ::v-deep .select_arrow {
        .multiselect__tags {
            .multiselect__placeholder {
                padding: 7px 0 6px 10px;
                margin-bottom: 0;
            }

            .multiselect__input {
                padding-left: 10px !important;
            }
        }
    }

    ::v-deep .multiselect {
        width: 100%;
        height: 38px;
        min-height: 38px;
        background: transparent 0 0 no-repeat padding-box;
        cursor: pointer;
        border: 1px solid #798387;
        border-radius: 5px;
    }

    ::v-deep .multiselect__tags {
        background: transparent;
        padding-left: 0;
        min-height: 38px;
        padding-top: 0;
    }

    ::v-deep .multiselect__tag {
        background: transparent;
        color: $black;
        margin-top: 6px;
    }

    ::v-deep .multiselect__tag-icon:after {
        color: red;
    }

    ::v-deep .multiselect__tag-icon:hover {
        background: transparent;
    }

    ::v-deep .multiselect__option--highlight,
    ::v-deep .multiselect__option--highlight:after {
        background: $blue_grey;
        color: $black;
    }

    ::v-deep .multiselect__select:before {
        border-width: 0;
        border-style: none;
    }

    ::v-deep .multiselect__input {
        margin: 0;
        padding: 0;
    }
</style>